/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useMemo, lazy } from 'react'
import Navbar from '../../../component/navbar.js';
import Footer from '../../../component/footer';
import styles from './checkout.css';
import Orange from '../../../assets/images/orange-money.svg';
import carte from '../../../assets/images/carte-de-debit.png';
import especes from '../../../assets/images/paiement-en-especes.png';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Select from 'react-select'
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';



export default function Checkout() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const {state} = useLocation();
    const [formules, setFormules] = useState([]);
    const [adresses, setAdresses] = useState([]);
    const [forfait, setForfait] = useState(undefined);
    const [options_adresses, setOptionAdresses] = useState([]);
    const [choix_formule, setChoixFormule] = useState(undefined);
    const [choix_adresse, setChoixAdresse] = useState(undefined);
    const [prix, setPrix] = useState(0);
    const [total, setTotal] = useState(0);
    const [reduction, setReduction] = useState(0);
    const [tva, setTVA] = useState(0);
    const [codePromo, setCodePromo] = useState("");
    const [coupon, getCoupon] = useState("");
    const [pays, setPays] = useState("");
    const [orange_check, setOrangeCheck] = useState(false);
    const [cb_check, setCBCheck] = useState(false);
    const [especes_check, setEspecesCheck] = useState(false);
    const navigate = useNavigate();
    const options = useMemo(() => countryList().getData(), []);
    

    const CustomOption = (props) => {
        return (
          <div
            {...props.innerProps}>
            <div> {props.data.adresse+ " "}  {props.data.code_postal + " "} {props.data.ville+" "}</div> 
            <div>{props.data.nom + " "} {props.data.prenom}</div>
            

          </div>
        );
    };
    
    function checkCoupon(code){
        let token = localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                         'Authorization': 'Bearer '+token
            }
        }
        fetch(backend_url+'/coupons/'+code+'/'+forfait+'/'+(choix_formule ? choix_formule.id : ''), requestOptions)
        .then(response => {
                if (response.ok){
                    return response.json();
                }else{
                    console.log("error when retrieving the coupon")
                }
                if (response.status === 404) {
                    toast.error("Le coupon n'est pas valide");
                }
                
        })
        .then(data => {
            setReduction(data?.reduction !== undefined ? data.reduction : 0);
            calculateNewPrice();

            if (data?.reduction === undefined){
                setCodePromo("");

            }
            if (data?.detail !== undefined ){
                toast.error("Votre coupon n'est pas valide");
            }
            
        });
    }

    function addAdresseFacturation(){
        let token = localStorage.getItem("token");
        let headers = {};
        let endpoint = "/adresses_facturation/";
            
        headers = { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
        }
        
        const nom = document.getElementById('nom').value;
        const prenoms = document.getElementById('prenom').value;
        //const pays = document.getElementById('pays').value;
        const ville = document.getElementById('ville').value;
        const code_postal = document.getElementById('code-postal').value;
        const adresse = document.getElementById('adresse').value;
        const complement_adresse = document.getElementById('complement-adresse').value;

        
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ 
            nom: nom,
            prenoms: prenoms,
            pays: pays.label,
            ville: ville,
            code_postal: code_postal,
            adresse: adresse,
            complement_adresse: complement_adresse,
          
          })};
        
        fetch(backend_url+endpoint, requestOptions)
        .then(response => {
            
            return response.json();
        })
        .then(data => {
            if(data.detail != undefined){
                toast.error(data.detail);
            }else{
                toast.success("Adresse ajoutée avec succès!");
                getDBAdresses();
            
            }
        
        })
        .catch(error => {
            
            toast.error("Impossible d'ajouter l'adresse de facturation");
        });
      }

    const handleClick = () => {
        console.info('You clicked the Chip.');
      };
    
      const handleDelete = () => {
        console.info('You clicked the delete icon.');
        setCodePromo("");
        calculateNewPrice();
        setReduction(0);
      };
    
    function ajoutCodePromo(){
        let code = document.getElementById("code-promo").value;
        setCodePromo(code);
        checkCoupon(code);
        document.getElementById("code-promo").value = "";
    }
    function calculateNewPrice(){
        setTotal(parseInt(state.prix + (tva/100) * state.prix - (reduction/100)* state.prix));
    }
    async function getFormules() {
        try {
          const response = await fetch(backend_url+'/plan_souscription/');
          const data = await response.json();
          setFormules(data);
          console.log(formules);
        } catch (error) {
          console.error(error);
        }
    }

    function choixPays(value){
        setPays(value)
    }

    function choixCheckbox(name){
        console.log(name);
        if(name === 'cb'){
            setCBCheck(true)
            setOrangeCheck(false)
            setEspecesCheck(false)
            //navigate('/payment')

        }
        if(name === 'especes'){

            setCBCheck(false)
            setOrangeCheck(false)
            setEspecesCheck(true)
        }
        if(name === 'orange-money'){

            setCBCheck(false)
            setOrangeCheck(true)
            setEspecesCheck(false)
            //navigate('/checkout-offline')
        }
    }

    function getDBAdresses(){
        let token = localStorage.getItem("token");
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                         'Authorization': 'Bearer '+token
            }
        }
        fetch(backend_url+'/adresses_facturation/', requestOptions)
        .then(response => {
                if (response.ok){
                    return response.json();
                }
                
        })
        .then(data => {
            setAdresses(data);
            let options = [];
            data.forEach((element,index) => {
                options.push({
                    label: element.adresse +" "+ element.code_postal + " "+ element.ville,
                    value: element.adresse,
                    nom: element.nom,
                    obj: element,
                    adresse: element.adresse,
                    ville: element.ville,
                    code_postal: element.code_postal,
                    
                });
            });

            setOptionAdresses(options);
        });
    }


    function paiementClick(){
        let code = document.getElementById("code-promo").value;
        setCodePromo(code);
        //
        console.log(cb_check);
        console.log(orange_check);
        if (cb_check){
            navigate('/payment')
        }
        if (especes_check){
            console.log(codePromo)
            navigate('/checkout-offline' ,
                {
                    state:{ 
                        formule: choix_formule,
                        codePromo: codePromo,
                        choix_adresse: choix_adresse,
                        prix: prix,
                        forfait: forfait,
                        reduction: reduction,
                        title: "Espèces"

                }
                
                })
        
        }
        if (orange_check){
            console.log(codePromo)
            navigate('/checkout-offline' ,
                {
                    state:{ 
                        formule: choix_formule,
                        codePromo: codePromo,
                        choix_adresse: choix_adresse,
                        prix: prix,
                        forfait: forfait,
                        reduction: reduction,
                        title: "Orange money"

                }
                
                })
        }
        else{
            toast.error("Veuillez choisir un moyen de paiement svp")
        }
    }
    useEffect(()=>{
        document.title = "Facturation";
        getDBAdresses();
        getFormules()
        setPrix(state.prix);
        setForfait(state.forfait);
        setChoixFormule(state.formule);
        setTotal(state.prix + (tva/100) * state.prix - (reduction/100)* state.prix)

        const checkboxes = document.querySelectorAll('.single-checkbox');

        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', function() {
                if (this.checked) {
                    checkboxes.forEach(other => {
                        if (other !== this) other.checked = false;
                    });
                }
            });
        });
    },[]);

    function changeAdresse(e){
        setChoixAdresse(e);
        console.log(e.target);
        console.log( document.getElementById('facturation').value);
        console.log(choix_adresse);

    }

    useEffect(()=>{
        calculateNewPrice();
    }, [reduction])

    return (
        <div className={styles}>
            <ToastContainer />
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">

                <Navbar />

                <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" id="home">
                    <div className="absolute inset-0 navbar-background"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Formulaire de paiement</h3>
                        </div>
                    </div>
                </section>

            
        
                <section className="relative">

                    <div className='checkout-div'>
                        <div className='main-content-paiement hidden'>
                    
                            <div className="">
                                <h2 className="titre-checkout">Informations de facturation</h2>
                               
                                <div className="profile-info-account">
                                <div className="profile-details-account">

                                       <div className="name-container">
                                            <div className="name-field">
                                                <label htmlFor="lastname" className="visually-hidden">Nom</label>
                                                <div className="label-checkout">Nom</div>
                                                <input id="nom" className="input-colismi coupon-input  py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                                            </div>
                                            <div className="name-field">
                                                <label htmlFor="firstname" className="visually-hidden">Prenom</label>
                                                <div className="label-checkout">Prenom</div>
                                                <input id="prenom" className="input-colismi coupon-input  py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                                            </div>
                                        </div>
                                    
                                        <label htmlFor="email" className="visually-hidden">Pays</label>
                                    <div className="label-checkout">Pays</div>
                                    <Select id="pays" options={options} value={pays} onChange={choixPays} />


                                    <label htmlFor="email" className="visually-hidden">Adresse</label>
                                    <div className="label-checkout">Adresse</div>
                                    <input id="adresse" className="input-colismi coupon-input  w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                                    
                                    <label htmlFor="email" className="visually-hidden">Complement d'adresse</label>
                                    <div className="label-checkout">Complement d'adresse</div>
                                    <input id="complement-adresse" className="input-colismi coupon-input  w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />

                                    <div className="name-container">
                                        <div className="name-field">
                                            <label htmlFor="lastname" className="visually-hidden">Ville</label>
                                            <div className="label-checkout">Ville</div>
                                            <input id="ville" className="input-colismi coupon-input  py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                                        </div>
                                        <div className="name-field">
                                            <label htmlFor="firstname" className="visually-hidden">Code Postal</label>
                                            <div className="label-checkout">Code Postal</div>
                                            <input id="code-postal" className="input-colismi coupon-input  py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" />
                                        </div>
                                    </div>
                                    
            
                                </div>
                               
                                </div>
                            </div>
                            <button className='view-route-btn' style={{marginTop: "10px"}} onClick={addAdresseFacturation}>Ajouter</button>
                           
                        </div>
                        <div className='main-content-paiement'>
                            <div>
                                <h2 className="titre-checkout">{choix_formule && choix_formule.nom}</h2>
                                <br/>
                                <p>{choix_formule && choix_formule.description}</p>
                                { choix_formule &&
                                <div className="p-6">
                                    <div className="pb-8">
                                        
                                        <div className="mb-6 dark:text-white/50">
                                            <span className="relative h6 -top-5 text-xl"></span>
                                            
                                        
                                            
                                            
                                        </div>
                                        <img className='image-plan' src={choix_formule.image}/>
                                    
                                        
                                    </div>
                                    <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                                        <ul className="self-start pt-8  details-abonnement" style={{height: 'auto'}}>
                                            {
                                            Object.entries(choix_formule.features).map(([key, value]) => (

                                                <li className="flex items-center my-1 text-slate-400" key={key}>
                                                    {value == "Oui" &&
                                                    <div className='formule-feature'>
                                                            <i className="uil uil-check-circle text-sm text-green-600 me-2"></i>
                                                            <span>{key}</span>
                                                        </div>
                                                    }
                                                    {value == "Non" &&
                                                    <div className='hidden'>
                                                            <i className="uil uil-check-circle text-sm text-red-700 me-2"></i>
                                                            <span>{key}</span>
                                                        </div>
                                                    }
                                                    
                                                
                                                </li>
                                            ))

                                                
                                            }
                                        </ul>
                                    <div className='conditions-abonnement'>
                                        <br/>
                                        <h1 className='titre-condition'>Conditions</h1>
                                        <ul>
                                        {
                                           Object.entries(choix_formule.conditions).map(([key, value]) => (

                                            <li className="flex items-center my-1 text-slate-400" key={key}>
                                                
                                                   <div className='formule-feature'>
                                                        <span>{value}</span>
                                                    </div>
                                            </li>
                                          ))

                                            
                                        }

                                        </ul>

                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='right-panel'>
                            <div className='top-paiement'>
                                    <h2 className="titre-checkout">Détails de votre commande</h2>
                                    <br/>
                                    <div>
                                        <h1>Vous avez un coupon de réduction? rentrez le ici</h1>
                                    </div>
                                    <div className='coupon-div'>
                                        <input placeholder='ENTRER VOTRE COUPON' id="code-promo" className='input-colismi coupon-input py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2' />
                                        <button className='view-route-btn' onClick={ajoutCodePromo}>Valider</button>
                                    </div>
                                    <div>
                                        <Stack direction="row" spacing={1}>
                                            { codePromo !== "" &&
                                                <Chip
                                                label={codePromo}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                            />
                                            }
                                                
                                        </Stack>
                                    </div>
                                    <hr />
                                    <div className='pricing-detail'>
                                        <div>
                                            <p>{choix_formule && choix_formule.nom}</p>

                                        </div>
                                        <div>
                                            <p>{prix && prix}€</p>
                                        </div>

                                    </div>
                                    { tva > 0 &&
                                    <div className='pricing-detail'>
                                        <div>
                                            <p>TVA</p>

                                        </div>
                                        <div>
                                            <p>{tva}%</p>
                                        </div>
                                    </div>
                                    }
                                    { reduction !==0 &&
                                    <div className='pricing-detail'>
                                        <div>
                                            <p>Coupon</p>

                                        </div>
                                        <div>
                                            <p>-{reduction}%</p>
                                        </div>
                                    </div>
                                    }

                                    <hr/>
                                    <div className='pricing-detail'>
                                        <div>
                                            <strong>Total</strong>

                                        </div>
                                        <div>
                                            <strong>{total}€</strong>
                                        </div>

                                    </div>
                                    <hr/>
                                    <div className='hidden'>
                                        <br/>
                                        <p>Je choisis mon adresse de facturation</p>
                                        <br/>
                                        <Select id="facturation" options={options_adresses}  components={{ Option: CustomOption }} value={choix_adresse}  onChange={changeAdresse}/>
                                    </div>
                                    <br/>
                                    <p>
                                        Options de paiement
                                    </p>
                                        <div>
                                        <div className='paiement-option hidden'>
                                                <div>
                                                    <input type="checkbox"  className="single-checkbox" id="cb-paiement"  name="cb-paiement" onChange={()=> choixCheckbox('cb')} />
                                                    <label htmlFor="cb-paiement"></label>
                                                </div>
                                                <div className='option-img-checkbox'>
                                                    
                                                    <img  className="img-option-paiement" src={carte} />
                                                    <strong className='titre-option-paiement'> carte bancaire</strong>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div className='paiement-option'>
                                                <div>
                                                    <input type="checkbox" className="single-checkbox" id="orange-money" name="orange-money"  onChange={()=> choixCheckbox('orange-money')}/>
                                                    <label htmlFor="orange-money"></label>
                                                </div>
                                                <div className='option-img-checkbox'>
                                                    <img  className="img-option-paiement" src={Orange} />
                                                    <strong className='titre-option-paiement'> Orange money</strong>
                                                </div>

                                            </div>


                                            
                                           
                                        </div>
                                        <div>
                                            <div className='paiement-option'>
                                                <div>
                                                    <input type="checkbox" className="single-checkbox" id="especes-money" name="especes-money"  onChange={()=> choixCheckbox('especes')}/>
                                                    <label htmlFor="especes-money"></label>
                                                </div>
                                                <div className='option-img-checkbox'>
                                                    <img  className="img-option-paiement" src={especes} />
                                                    <strong className='titre-option-paiement'> Espèces</strong>
                                                </div>

                                            </div>

                                            
                                            
                                           
                                        </div>
                                    <button disabled={!orange_check && !especes_check} className='w-full colismi-button input-wrapper bouton-paiement' onClick={paiementClick}>Passer au paiement</button>

                            </div>
                        </div>

                    </div>
                    

                </section>

                <Footer />
            </body>
        </div>
    )
}
