/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import Navbar from '../../../component/navbar'
import Footer from '../../../component/footer';
import { ToastContainer, toast } from 'react-toastify';
import styles from './checkout-offline.css';
import { useNavigate, useLocation } from 'react-router-dom';

var validator = require('validator');

export default function CheckoutOffline() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const {state} = useLocation();
    const formule = state.formule;
    const [choix_formule, setChoixFormule] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [choix_adresse, setChoixAdresse] = useState(undefined);
    const [coupon, setCoupon] = useState("");
    const [facture, setFacture] = useState(undefined);
    const [forfait, setForfait] = useState(undefined);
    const [tarification, setTarification] = useState(undefined);
    const [prix, setPrix] = useState(0);
    const [tva, setTVA] = useState(0);
    const [reduction, setReduction] = useState(0);
    const [total, setTotal] = useState(0);
    const [total_tva, setTotalTva] = useState(0);
    console.log(state);

    function calculateNewPrice(){
        setTotal(parseInt(state.prix + (tva/100) * state.prix - (reduction/100)* state.prix));
    }


    function addHistorique(id_souscription) {
        let token = localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
            id_user: user.id,
            id_subscription: id_souscription,
            montant: total,
            date_facturation: new Date().toISOString(),
            date_paiement: new Date().toISOString(),
            statut: 'ATTENTE',
            commentaire: 'Paiement en attente',
            coupon: coupon
            })
        };

        fetch(backend_url + '/historique_facturation/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("Error when adding to historique");
                }
            })
            .then(data => {
                if (data) {
                    console.log("Historique added successfully!");
                    setFacture(data);
                    navigate('/confirmation-souscription', { state: {facture: data, message: "Votre abonnement a été ajouté avec succès!, Veuillez vous rapprocher de nos agences partenaires afin d'effectuer le paiement" } });
                }
            });
    }
       
    function checkCoupon(code){
        if(code === "" || code === undefined){
            return
        }
        console.log(state);
        let token = localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                         'Authorization': 'Bearer '+token
            }
        }
        fetch(backend_url+'/coupons/'+code+'/'+state.forfait+'/'+state.formule.id , requestOptions)
        .then(response => {
                if (response.ok){
                    return response.json();
                }else{
                    console.log("error when retrieving the coupon")
                    toast.error("Le coupon n'est pas valide");
                }
                
        })
        .then(data => {
            setReduction(data?.reduction !== undefined ? data.reduction : 0);
            calculateNewPrice();      
            setCoupon(code);      
        });
    }

    

    function envoiMessaage(){
        const nom = document.getElementById('name').value;
        const commentaire = document.getElementById('comments').value;
        const email = document.getElementById('email').value;
        const sujet = document.getElementById('subject').value;

        if (!validator.isEmail(email)){
            toast.error("L'adresse mail renseignée n'est pas valide");
        return
       }

        if (nom === "" || commentaire === "" || email === "" || sujet === ""  ){
            return
        }
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            nom: nom,
            commentaire: commentaire,
            email: email,
            sujet: sujet
          
          })};
        
        fetch(backend_url+'/contact/add', requestOptions)
        .then(response => {
            if(response.ok){
                document.getElementById('name').value = "";
                document.getElementById('comments').value = "";
                document.getElementById('email').value = "";
                document.getElementById('subject').value = "";
            }
            return response.json();
        })
        .then( () => {
            toast.success("Votre message a bien été envoyé!");
        })
    }

    function getMe(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/user/me', requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }
            
        })
        .then(data => {
            if(data == undefined){
                return
            }
            setUser(data);

            localStorage.setItem('pseudo', data.pseudo);
            localStorage.setItem('id', data.id);

        })
    }

    function addSubscription() {
        let token = localStorage.getItem("token");
        console.log(state);
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                user_id: user.id,
                plan_id: choix_formule.id,
                date_debut: new Date().toISOString(),
                date_fin: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
                actif: true,
                date_ajout: new Date().toISOString(),
                prix: total,
                coupon: coupon,
                forfait: forfait, 
            })
        };

        fetch(backend_url + '/subscriptions/', requestOptions)
            .then(response => {
                if (response.ok) {
                    
                    return response.json();
                } else {
                    console.log("Error when adding the subscription");
                }
            })
            .then(data => {
                addHistorique(data.id);
                if (data) {
                    toast.success("Subscription added successfully!");
                    
                    
                }
            });
    }

    useEffect(()=>{
        getMe();
        setChoixFormule(state.formule);
        setPrix(state.prix);
        setForfait(state.forfait);
        console.log(state);
        //setReduction(state.reduction);
        
        if (state.choix_adresse !== undefined){
            setChoixAdresse(state.choix_adresse.obj);
        }
        setTotalTva(tva*(state.prix-reduction*state.prix)/100 )
        if(state.prix == state.formule.prix_mensuel){
            setTarification("Mensuel");
        }else{
            setTarification("Annuel");
        }
        
        calculateNewPrice();
        setTimeout(() => {
            checkCoupon(state.codePromo);
        }, 2000);
    }, [])

    useEffect(()=>{
        calculateNewPrice();
    }, [reduction])

    return (
        <div className={styles}>
            <ToastContainer />
            <Navbar />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Paiement par {state.title}</h3>
                    </div>
                </div>
            </section>
            <section className="relative">
                <div className="middle-info">
                    <div >
                        <p>Vous n'avez pas de carte bancaire, pas de panique. Vous pourrez effectuer les paiements dans une agence locale</p>
                        <div className="container-plan">
                                <h1>Récapitulatif de l'Abonnement</h1>
                                <br/>

                                
                                <div className="customer-info">
                                    <h2>Informations du Client</h2>
                                    <p><strong>Nom:</strong> Delka</p>
                                    <p><strong>Email:</strong> {user && user.email}</p>
                                    <p><strong>Date d'abonnement:</strong> {new Date().toLocaleDateString()}</p>
                                </div>

                                <div className="subscription-details">
                                    <h2>Détails de l'Abonnement</h2>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Plan</th>
                                                <th>Période de Facturation</th>
                                                <th>Prix {tarification}</th>
                                                <th>Prix Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{state.formule.nom}</td>
                                                <td>{tarification}</td>
                                                <td>{prix} €</td>
                                                <td>{prix} €</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="payment-summary">
                                    <p className="total">Sous-total: {prix} €</p>
                                    {reduction > 0 && <p className="total">Réduction: -{reduction} %</p>}
                                    {tva > 0 && <p className="total">Taxes (TVA 20%): {total_tva} €</p>}
                                    <p className="total">Total à payer: <strong>{total} €</strong></p>
                                </div>
                                
                            </div>
                            <div style={{width:'100%', height: '60px'}}>
                                    <div className="pay-button">
                                        <button onClick={addSubscription}>Valider ma souscription</button>
                                    </div>
                            </div>
                        
                        

                    <div className='hidden'>
                        <p>Vous pouvez effectuer les paiements dans une agence locale</p>
                        <strong>Burkina Faso</strong>
                            <h1 className='title-agencies'>Nos agences partenaires</h1>

                                <ul className="agency-list">
                                    <li className="agency-item">
                                        <h2>Patience Plus</h2>
                                        <p>Ouagadougou , Burkina Faso</p>
                                    </li>
                                    <li className="agency-item">
                                        <h2>Justi Shop</h2>
                                        <p>Ouagadougou: Burkina Faso</p>
                                    </li>
                                </ul>
                    </div>
                </div>

                    
               
                   
                </div>
            </section>
            <div className="container-fluid relative" >
                
               
            </div>
            <Footer />
        </div>
    )
}