/* eslint-disable react/no-unescaped-entities */
import 'react-tabs/style/react-tabs.css';
import './mon-compte.css';
import {React, useEffect, useState} from 'react'
import NavbarV2 from '../../../component/navbar-v2.js'
import Footer from '../../../component/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from "react-router-dom";
import 'moment/locale/fr';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import Demande from '../../v2/demande/demande.js';
import MonTrajet from '../../v2/mon-trajet/mon-trajet.js';
import MaReservation from '../../v2/ma-reservation/ma-reservation.js';
import AccountEdit from '../account-edit/account-edit.js';
import Chip from '@mui/material/Chip';

moment.locale('fr');


Modal.setAppElement('body');
export default function MonCompte() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [mesTrajets, setMesTrajets] = useState([]);
    const [facturations, setFacturations] = useState([]);
    const [selectedAbonnement, setSelectedAbonnement] = useState(undefined);
    const [mesTrajetsFiltres, setMesTrajetsFiltres] = useState([]);
    const [filtreTrajet, setFiltreTrajet] = useState("ACTIF");
    const [filtreReservation, setFiltreReservation] = useState("");
    const [mesReservations, setMesReservations] = useState([]);
    const [abonnements, setMesAbonnements] = useState([]);
    const [mesReservationsFiltres, setMesReservationsFiltres] = useState([]);
    const [mesDemandes, setMesDemandes] = useState([]);
    const [demadeToDelete, setDemadeToDelete] = useState(undefined);
    const [selectedReservation, setSelectedReservations] = useState(undefined);
    const [selectedTrajet, setSelectedTrajet] = useState(undefined);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [user, setUser] = useState({
        id: 0,
        pseudo: "",
        nom: "",
        prenom: "",
        email: "",
        facebook: "",
        linkedin: "",
        website: "",
        telephone: "",
        twitter: "",
        type: "",
        is_verified: false
    });
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [modalDemandeAnnuler, setModalDemandeAnnuler] = useState(false);
    let subtitle;
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    
    const options_trajets = [
        { value: 'ACTIF', label: 'Trajets en cours' },
        { value: '', label: 'Tous les trajets' },
        { value: 'ANNULE', label: 'Trajets annulés' },
        { value: 'TERMINE', label: 'Trajets terminés' }
      ]
    
    const options_reservations = [
        { value: '', label: 'Toutes les reservations' },
        { value: 'ACCEPTE', label: 'Réservations en cours' },
        { value: 'ANNULE', label: 'Réservations annulées' },
        { value: 'REJETE', label: 'Réservations rejetées' }

    ]


    function editTrajet(trajet){
        console.log(trajet);
        navigate("/user-trajet", {state: {trajet: trajet}});

    }

    function deleteTrajet(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'POST',
          headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
        },
          body: JSON.stringify({ 
            ...selectedTrajet         
          })
        };
        setCancelModalIsOpen(false);
        
        fetch(backend_url+'/trajets/cancel', requestOptions)
        .then(response => {
            if(response.ok){
                toast.success("Trajet annulé avec succès!");
                getTrajets();
                
                
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            
        })
    }

    function onTabSelect(index){
        setTabIndex(index);
        console.log(index);
    }

    function getMe(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/user/me', requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }
            
        })
        .then(data => {
            console.log(tabIndex);
            if (tabIndex != 0){
                return
            }

            if(data == undefined){
                return
            }
            
            setUser({
                id: data.id,
                pseudo: data.pseudo,
                nom: data.nom,
                prenom: data.prenom,
                email: data.email,
                facebook: data.facebook,
                linkedin: data.linkedin,
                website: data.website,
                telephone: data.telephone,
                twitter: data.twitter,
                description: data.description,
                type: data.type,
                is_verified: data.is_verified
            });
            setUser(data);

            localStorage.setItem('pseudo', data.pseudo);
            localStorage.setItem('id', data.id);

            /*
            document.getElementById('pseudo').value = data.pseudo;
            document.getElementById('nom').value = data.nom;
            document.getElementById('prenom').value = data.prenom;
            document.getElementById('email').value = data.email;
            document.getElementById('facebook').value = data.facebook;
            document.getElementById('linkedin').value = data.linkedin;
            document.getElementById('twitter').value = data.twitter;
            document.getElementById('website').value = data.website;
            document.getElementById('telephone').value = data.telephone;
            document.getElementById('description-profil').value = data.description;*/
        })
    }

    function getReservations(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/reservation', requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
            setMesReservations(data);
            setMesReservationsFiltres(data);
        })
    }

    function getDemandes(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/colis', requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }else{
                if(response.status === 401){
                    localStorage.removeItem("token");
                    localStorage.removeItem("pseudo");
                }
                
                navigate("/login");
            }
            
        })
        .then(data => {
            setMesDemandes(data);
        })
    }

    function getAbonnements(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/subscriptions/user', requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }else{
                if(response.status === 401){
                    localStorage.removeItem("token");
                    localStorage.removeItem("pseudo");
                }
                
                navigate("/login");
            }
            
        })
        .then(data => {
            setMesAbonnements(data);
        })
    }

    function getFacturations(id_souscription){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/historique_facturation/'+id_souscription, requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }
            
        })
        .then(data => {
            setFacturations(data);
        })
    }

    function afficherCommentaires(reservation){
        navigate("/reservation", {state: {reservation: reservation, trajet: reservation.trajet}});
    }

    function openModal(reservation) {
        setSelectedReservations(reservation);
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#5f2972';
        subtitle.style.fontSize = "20px";
    }
    function closeModal() {
        setIsOpen(false);
    }

    function openCancelModal(trajet){
        setSelectedTrajet(trajet);
        setCancelModalIsOpen(true);
    }

    function closeCancelModal() {
        setCancelModalIsOpen(false);
        setModalDemandeAnnuler(false);
    }

    function annulerReservation(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
          body: JSON.stringify({ 
            ...selectedReservation
            
          },)};
        
        fetch(backend_url+'/reservation/cancel', requestOptions)
        .then(response => {

            if (response.ok) {
               
                closeModal();
                toast.success("La réservation a bien eté annulée!");
                getReservations();
            }
            return response.json();
        })
    }

    function showDemandeComments(data){
        navigate("/comments-demande", {state: {demande: data}});
    }

    function deconnecter(){
        localStorage.removeItem("token");
        localStorage.removeItem("pseudo");
        navigate("/");
    }

    function getTrajets(){
        let token = localStorage.getItem("token");
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                         'Authorization': 'Bearer '+token
            }
        }
        fetch(backend_url+'/user/trajets/all', requestOptions)
        .then(response => {
                if (response.ok){
                    return response.json();
                }else{
                    navigate("/login");
                }
                
        })
        .then(data => {
            setMesTrajets(data);
            setMesTrajetsFiltres(data);
        });
    }

    function filtreTrajetChanged(item){
       if(tabIndex !== 1)
       return
       let filtre = item.value; //document.getElementById('filtre-trajet').value;
       setFiltreTrajet(filtre);
        console.log(filtre);
       
       let arr= [];
       mesTrajets.forEach(element => {
            if(element.statut === filtre || filtre === ""){
                arr.push(element);
                console.log(element);
            }
       });
       setMesTrajetsFiltres(arr);
    }

    function filtreReservationChanged(item){
        if(tabIndex !== 2)
        return
        let filtre = item.value;
        setFiltreReservation(filtre);
         console.log(filtre);
        
        let arr= [];
        mesReservations.forEach(element => {
             if(element.statut === filtre || filtre === ""){
                 arr.push(element);
                 console.log(element);
             }
        });
        setMesReservationsFiltres(arr);
     }

     function supprimerDemande(){
        let token = localStorage.getItem("token");
        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token
        
        },
          body: JSON.stringify({ 
            ...demadeToDelete
            
          },)};
        
        fetch(backend_url+'/colis/delete', requestOptions)
        .then(response => {

            if (response.ok) {
                toast.success("La Demannde a bien été annulée!");
                closeCancelModal();
                getDemandes();
            }
            return response.json();
        })

     }
    
     function  abonnementClcik(abonnement){

        setSelectedAbonnement(abonnement);
        getFacturations(abonnement.id);
     }

     function getFormattedDate(date_ajout){
        // Format date with Moment.js
        let formattedDate = "";
        try {
            formattedDate = moment.utc(date_ajout).fromNow();
        } catch (error) {
            console.error("Error parsing date:", error);
            formattedDate = "Invalid Date";
        }
        return formattedDate;
    }

    useEffect(()=>{
        document.title = "Compte";
       
        //try to get current user
        if(tabIndex == 0){
            getMe();
        }
        getTrajets();
        getReservations();
        getDemandes();
        getAbonnements();

        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false);
          if(!isLoggedin){
            navigate("/travel-search");
         }
        }else{
          setIsLoggedin(true)
        }

        
        

    }, []);

    return (
        <div>
            <ToastContainer />
            <NavbarV2 />
            
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Mon compte</h3>
                    </div>
                </div>
            </section>
            <section className="relative main-content-compte">
                <div className="container content-zone">
                    <div className="section-sidebar">
                        <Tabs
                        selectedIndex={tabIndex} onSelect={(index) => onTabSelect(index)}
                        className="react-tabs"
                        >
                            <TabList>
                            <Tab>Mon profil</Tab>
                            <Tab>Mes trajets</Tab>
                            <Tab>Mes réservations</Tab>
                            <Tab>Mes demandes</Tab>
                            <Tab>Réglages Généraux</Tab>
                            <Tab>Abonnements</Tab>
                            </TabList>

                            <TabPanel id="pannel-profil">
                                { user &&
                                    <AccountEdit
                                      user={user}
                                      toast={toast}
                                    />
                                }
                            
                            </TabPanel>
                            <TabPanel id='panel-trajets'>
                            <div id="button-zone-trajets" style={{width: '100%',display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                    <button className='colismi-button-cm'><Link to="/travel-add">Ajouter un trajet</Link> </button>
                                </div>
                                
                                <div >
                                             <Select 
                                                label="Afficher"
                                                placeholder="Afficher"
                                                inputId='filtre-trajet'
                                                defaultValue={filtreTrajet}
                                                name='type-transport'
                                                onChange={filtreTrajetChanged}
                                                options={options_trajets} />
                                </div>
                            </div>
                            
                            <div id="user-trajet-zone">
                                    {mesTrajetsFiltres.length === 0 &&

                                        <p>Il n'y a aucun trajet à afficher </p>

                                    }
                            {
                                 mesTrajetsFiltres.map(trajet => (
                                    (trajet.statut === filtreTrajet || filtreTrajet === "") &&
                                    <MonTrajet key={trajet.id}
                                    trajet={trajet}
                                    func_gerer={() => editTrajet(trajet)}
                                    func_annuler={() => openCancelModal(trajet)}
                                    />
                                ))
                            }
                            </div>    
                            

                           
                            </TabPanel>
                            <TabPanel id="panel-reservation">
                            <div id="button-zone-trajets" style={{width: '100%',display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                   
                                </div>
                                
                                <div >
                                             <Select 
                                                label="Afficher"
                                                placeholder="Afficher"
                                                inputId='filtre-reservation'
                                                defaultValue={filtreReservation}
                                                onChange={filtreReservationChanged}
                                                options={options_reservations} />
                                </div>
                            </div>
                            {mesReservationsFiltres.length == 0 &&

                                <p>Aucune réservation à afficher </p>

                            }
                            <div id="zone-reservation">
                            {
                                mesReservationsFiltres.map(reservation => (
                                    (reservation.statut === filtreReservation || filtreReservation === "") &&
                                
                                    <MaReservation
                                     key={reservation.id}
                                     reservation={reservation}
                                     func_voir={() => afficherCommentaires(reservation)}
                                     func_annuler= {() => openModal(reservation)}
                                     toast={toast}
                                    />
                                ))
                            }
                            </div>

                            </TabPanel>
                            <TabPanel id="panel-demandes">
                            <div id="button-zone-trajets">
                                <button className='colismi-button-cm'><Link to="/colis-request">Nouvelle demande</Link> </button>
                            </div>
                            <br/>

                            {mesDemandes.length == 0 &&

                                <p>Vous n'avez aucune demande en cours </p>

                            }
                            {
                                mesDemandes.map(demande =>(
                                    <Demande 
                                        key={demande.id}
                                        demande={demande}
                                        func_supprimer={() => {setModalDemandeAnnuler(true); setDemadeToDelete(demande)}}
                                        func_voir={() => showDemandeComments(demande)}
                                        toast={toast}
                                    />
    
                                ))
                            }
                            </TabPanel>
                            <TabPanel id="deconnexion">
                                
                                <div id="button-zone-trajets">
                                    <button className='colismi-button-cm' onClick={deconnecter}>Me Déconnecter</button>
                                 </div>
                            </TabPanel>
                            <TabPanel id="abonnement">
                                {abonnements.length == 0 &&
                                
                                    <p>Vous n'avez aucun abonnement en cours</p>
                                }
                                <div className='abonnement-list'>
                                { !selectedAbonnement &&  
                                    
                                    abonnements.map((abonnement, index) => {
                                        return(
                                        <div className="abonnement-item" key={index}>
                                                <div className="abonnement-item-header">
                                                    <h1>{abonnement.plan.nom}</h1>
                                                        <div>
                                                        { ! abonnement.actif &&
                                                            <Chip label="En attente" color="warning" />
                                                        }
                                                        {  abonnement.actif &&
                                                            <Chip label="Actif" color="success" />
                                                        }
                                                        
                                                    </div>

                                                </div>
                                                <div>
                                                    <h3>Prix: {abonnement.prix}€</h3>
                                                    <h3>Début: {getFormattedDate(abonnement.date_debut)}</h3>
                                                    <h3>Expire: {getFormattedDate(abonnement.date_fin)}</h3>
                                                </div>
                                                <div className='abonnement-item-footer'>
                                                    <div>
                                                    </div>
                                                    <Button className='colismi-button-effect' 
                                                        variant="outlined" sx={{ color: '#5f2972', borderColor: '#5f2972' }}
                                                        onClick={() => abonnementClcik(abonnement)}
                                                        >Détails</Button>
                                                </div>
                                            
                                            
                                        </div>
                                        )})
                                }
                               
                                </div>
                                <idv>
                                {selectedAbonnement &&
                                    <div>
                                        <div>
                                            <Button className='colismi-button-effect' 
                                                            variant="outlined" sx={{ color: '#5f2972', borderColor: '#5f2972' }}
                                                            onClick={() => setSelectedAbonnement(undefined)}
                                                            >Abonnements</Button>
                                        </div>
                                        <div>
                                            <br/>
                                            <p>Formule: {selectedAbonnement.plan.nom}</p>
                                            {facturations &&

                                                facturations.map( (facturation, index) =>(
                                                    <div key={index} className='facture-list'>
                                                        
                                                        <p>Numero: <strong>{facturation.numero}</strong></p>
                                                        <br/>
                                                        <p>Date de facturation: {moment(facturation.date_facturation).format("LL")}</p>
                                                        <p>Date de paiement: {moment(facturation.date_paiement).format("LL")}</p>
                                                        <p>Moyen de paiement: {facturation.moyen_paiement}</p>
                                                        <p>Coupon: {facturation.coupon}</p>
                                                        <p>Prix: {facturation.montant}€</p>

                                                        { ! facturation.statut == "ATTENTE "&&
                                                        
                                                            <p>Statut: <Chip label="En attente" color="warning" /></p>
                                                        }
                                                        {  facturation.statut != "ATTENTE" &&
                                                            <Chip label="Actif" color="success" />
                                                        }
                                                        
                                                    
                                                     </div>

                                                ))
                                            

                                            }
                                        </div>
                                        
                                        
                                    </div>

                                }
                                </idv>

                            </TabPanel>
                        </Tabs>
                    </div>

                        
                </div>
                
            </section>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h1 ref={(_subtitle) => (subtitle = _subtitle)}>Confirmation</h1>
                
                <br/>
                <div>Voulez vous vraiment annuler votre reservation ?</div>


                <div className='modal-actions' style={{display: 'flex', marginTop: "20px"}}>
                    <div>
                        <Button className='colismi-button-effect' onClick={annulerReservation} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Oui annuler</Button>

                    </div>
                    <div>
                        <Button className='colismi-button-effect' onClick={closeModal} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Abandonner</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={cancelModalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeCancelModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h1 ref={(_subtitle) => (subtitle = _subtitle)}>Confirmation</h1>
                
                <br/>
                <div>Voulez vous vraiment annuler votre trajet ?</div>


                <div className='modal-actions' style={{display: 'flex', marginTop: "20px"}}>
                    <div>
                        <Button className='colismi-button-effect' onClick={deleteTrajet} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Oui annuler</Button>

                    </div>
                    <div>
                        <Button className='colismi-button-effect' onClick={closeCancelModal} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Abandonner</Button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalDemandeAnnuler}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeCancelModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h1 ref={(_subtitle) => (subtitle = _subtitle)}>Confirmation</h1>
                
                <br/>
                <div>Voulez vous vraiment supprimer votre demande ?</div>


                <div className='modal-actions' style={{display: 'flex', marginTop: "20px"}}>
                    <div>
                        <Button className='colismi-button-effect' onClick={supprimerDemande} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Oui supprimer</Button>

                    </div>
                    <div>
                        <Button className='colismi-button-effect' onClick={closeCancelModal} sx={{ color: '#5f2972', borderColor: '#5f2972' }} variant="outlined">Abandonner</Button>
                    </div>
                </div>
            </Modal>
        </div>
        )
    }

