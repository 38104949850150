import React, { useEffect, useState } from 'react';
//import image from '../assets/images/questions.png';
import colis_request from '../assets/images/colismi/trajet/colis_request.svg'
import styles from './get-in-touch.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormControl } from '@mui/base/FormControl';
import Select from 'react-select';

export default function GetInTouch() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [choixVisibilite, setChoixVisibilite] = useState("Public");
    const [objetDemande, setObjetDemande] = useState("Information");
    const [villes, setVilles] = useState([]);
    const options = [
        { value: 'Public', label: 'Tout le monde' },
        { value: 'Professionnel', label: 'Professionels uniquement' }
      ]
    const options_type_demande = [
        { value: 'Information', label: "Demande d'information" },
        { value: 'Achat', label: "Achat d'articles" },
        { value: 'Envoi', label: 'Envoi de colis' },
        { value: 'Coursier', label: 'Je recherche un coursier' },
        { value: 'Assistance', label: "J'ai besoin d'assistance" }
    ]


    const [deactivateButton, setDeactivateButton] = useState(true);

    function formHasChanged(){
        setDeactivateButton(true);
        let array = [];

        const depart = document.getElementById('depart1').value;
        const arrivee = document.getElementById('arrivee1').value;
        const kilos = document.getElementById('kilos').value;

        let pseudo = "";
        let email = "";
        if (!isLoggedin){
            pseudo = document.getElementById('pseudo').value;
            email = document.getElementById('email').value;
            array.push(email);
            array.push(pseudo);
        }else{
            pseudo = ""
            email = ""
        }
       
        const description = document.getElementById('description').value;
        
        array.push(depart);
        array.push(arrivee);
        array.push(kilos);
        
        array.push(description);
        

        for (const element of array) {
            if(element === undefined ){
                return
            }
            if(element === "" ){
                return
            }
        }
        setDeactivateButton(false);
    }

    function addColisRequest(){

        const depart = document.getElementById('depart1').value;
        const arrivee = document.getElementById('arrivee1').value;
        const kilos = document.getElementById('kilos').value;
        
        const description = document.getElementById('description').value;
        let email = "";
        let pseudo = "";
        let endpoint = "/colis/add/public";
        let headers = { 'Content-Type': 'application/json' };
        if(!isLoggedin){
            email = document.getElementById('email').value;
            pseudo = document.getElementById('pseudo').value;
        }else{
            let token = localStorage.getItem("token");
            email = "";
            pseudo = "";
            endpoint = "/colis/add"
            headers = {  
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            
                }
        }
       
        

        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ 
            ville_depart: depart,
            ville_arrivee: arrivee,
            kilos: kilos,
            pseudo: pseudo,
            visibilite: choixVisibilite,
            objet: objetDemande,
            telephone: '',
            description: description,
            email: email
          
          })};
        
        fetch(backend_url+endpoint, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(() => {
            toast.success("Demande bien enregistrée!");

            document.getElementById('depart1').value = "";
            document.getElementById('arrivee1').value = "";
            document.getElementById('kilos').value = "";
            document.getElementById('description').value = "";
        })
      }
    
    function changeVisibilite(item){
        setChoixVisibilite(item.value);
    }

    function 
    changeObjetDemande(item){
        setObjetDemande(item.value);
    }

    useEffect(() => {
        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
          }

        getVilles();
        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
    
        
      }, []);


    return (
        <div style={styles}>
            <ToastContainer />
            <section className="relative md:py-24 " id="contact" style={{backgroundColor: "#F6F1F8", marginBottom: 0}}>
                <h3 className="mb-4 md:text-2xl text-xl font-medium titre-home" style={{textAlign: 'center'}}>Un colis à envoyer ? Un article à acheter? Faites la demande ici</h3>
                <p className="max-w-xl mx-auto sous-titre">Vous avez des contraintes de temps pour gérer la logistique et la livraison.
                    Faites votre demande ici pour que les transporteurs vous contactent.
                </p>
                <div className="container main-container">
                    <div className="grid grid-cols-1 text-center items-center gap-[30px]">
                        
                       
                        <img id='img-illustration' src={colis_request} style={{textAlign: 'center'}} />
                    </div>
                    <div className="grid grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <div name="myForm" id="myForm">
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    {!isLoggedin &&
                                    <div className="grid lg:grid-cols-12 lg:gap-[30px]">
                                       
                                        <div className="lg:col-span-6 mb-5">
                                            <FormControl required>
                                                <input required name="pseudo" onChange={formHasChanged} id="pseudo" type="text" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                    placeholder="Pseudo" />
                                            </FormControl>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" onChange={formHasChanged} className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                placeholder="Email" />
                                        </div>
                                        
                                    </div>
                                    }

                                    <div className="grid lg:grid-cols-12 lg:gap-[30px]">
                                        <div className="lg:col-span-6 mb-5">
                                        <Autocomplete
                                                    className='autocomplete'
                                                    disablePortal
                                                    fullWidth
                                                    onChange={formHasChanged}
                                                    options={villes}
                                                    id="depart1"
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  className='input-wrapper w-full input-colismi input-wrapper' variant="outlined"   style={{ backgroundColor: '#f6f1f8', color: '#b490c0' }} placeholder='Ville de départ du colis' />}
                                            />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                        <Autocomplete
                                                    className='autocomplete input-colismi'
                                                    disablePortal
                                                    fullWidth
                                                    onChange={formHasChanged}
                                                    options={villes}
                                                    id="arrivee1"
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  className='w-full input-wrapper' variant="outlined"   style={{ backgroundColor: '#f6f1f8', color: '#b490c0' }} placeholder="Ville d'arrivée du colis" />}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input onChange={formHasChanged} name="kilos" id="kilos" type='number' className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                                                placeholder="Poids du colis (Kg)" pattern="[0-9]*" />
                                        </div>
                                        <div className="mb-5">
                                            <Select className='input-colismi input-wrapper'
                                                    label="Objet de la demande"
                                                    placeholder="Objet de la demande"
                                                    inputId='objet-demande'
                                                    name='objet-demande'
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                          ...baseStyles,
                                                          backgroundColor: '#f6f1f8'
                                                        }),
                                                    }}
                                                    onChange={changeObjetDemande}
                                                    options={options_type_demande} />
                                        </div>
                                        <div className="mb-5">
                                            <Select className='input-colismi input-wrapper'
                                                    label="Visibilité de la demande"
                                                    placeholder="Visibilité de la demande"
                                                    inputId='visibilite-demande'
                                                    name='visibilite-demande'
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                          ...baseStyles,
                                                          backgroundColor: '#f6f1f8'
                                                        }),
                                                    }}
                                                    onChange={changeVisibilite}
                                                    options={options} />
                                        </div>
                                        <div className="mb-5">
                                            <textarea name="description" id="description"
                                                onChange={formHasChanged}
                                                className="input-wrapper input-colismi w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 h-28"
                                                placeholder="Description :"></textarea>
                                        </div>
                                    </div>
                                    <button disabled={deactivateButton} onClick={addColisRequest} id="submit" name="send"
                                        className="colismi-button w-full">Envoyer ma demande</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </div>
    )
}
